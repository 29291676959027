import React, { useEffect, useRef, useState } from 'react'
import useFormValidatonCustom, { validationObject } from '../../../../../../hooks/form_validation_hook'
import { useRESTapi } from '../../../../../../hooks/rest_API'
import { Brand, CustomFile, Product, ProductCategory, ProductImage, ProductItem, ProductItemKeys, ProductCategoryKeys, role, SalesOrderLine, OrderStatus, SalesOrder, Customer, ProductCustomizationOptionOrder, SalesOrderStatusHistory, ProductVariation, ProductVariationOption } from '../../../../../../types'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../redux/store'
import { IconLoader, IconPlus, IconX } from '@tabler/icons-react'
import { v4 as uuidv4 } from 'uuid';
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal'
import { Input } from '@/src/components/ui/input'
import { Button } from '@/src/components/ui/button'
import { Label } from '@/src/components/ui/label'
import { Table, TableHead, TableHeader, TableRow } from '@/src/components/ui/table'
import OrderBus from '@/src/pages/customer/Pages/singleorder/orderVerifybus'
import Tracking from './tracking'
import { paymentTypeId } from '@/src/types/databaseFeeds'




interface SalesOrderDetailsResponse extends SalesOrder {
    orderLines: ({
        product: Partial<ProductItem>,
        customizations?: ProductCustomizationOptionOrder[]
    } & SalesOrderLine)[],
    orderStatus: OrderStatus,
    customer: Partial<Customer>,
    order_status_dates: SalesOrderStatusHistory[],
    full_order_statuses: OrderStatus[],
    expectedDeliveryDate: string | null,
    delivery:  {
        I05_Delivery_id: string,
        I05_I19_Sales_order_id: string,
        I05_Tracking_number:string,
        I05_Estimated_arrival_date: string,
        I05_Actual_arrival_date: string
    } |null,

}
type variationDetails = ProductVariation & {
    variation_option: ProductVariationOption;
};

type salesOrderOverview = {
    orderLines: ({
        quantity: number,
        price: number,
        productName: string,
        productImage: string,
        customization?: ProductCustomizationOptionOrder[]
    })[],
    deliveryCharge:number,
    Price:number,
    taxPrice:number,
    orderId: string,
    date: string,
    orderSource: string,
    orderStatus: string,
    address: string,
    addressName: string,
    mobileNo: string,
    customerName: string,
    customerEmail: string
    full_order_statuses: OrderStatus[],
    order_status_dates: SalesOrderStatusHistory[],
    order_status: OrderStatus;
    expectedDeliveryDate: string | null,
    orderMethod:{
        id:string,
        name:string
    },
    delivery:  {
        I05_Delivery_id: string,
        I05_I19_Sales_order_id: string,
        I05_Tracking_number:string,
        I05_Estimated_arrival_date: string,
        I05_Actual_arrival_date: string
    } |null,
}
type TrackDetailsType = SalesOrder & {
    isCancellable: boolean,
    isSingleOrderline: number,
    order_status: OrderStatus;
    order_status_dates: SalesOrderStatusHistory[],
    full_order_statuses: OrderStatus[],
    expectedDeliveryDate: string | null,
    orderLines: (SalesOrderLine & {
        productItem: ProductItem & {
            variations: variationDetails[];
        };
        customizations: ProductCustomizationOptionOrder[];
    })[];
};




export function AddCategoryModal(
    {
        show,
        closeModal,
        id,
        updateUi,
        mode,
        setShow

    }:
        {
            show: boolean,
            closeModal: () => void,
            id?: string,
            updateUi: () => void,
            mode: 'add' | 'edit',
            setShow: (show: boolean) => any

        }
) {

    const api = useRESTapi();
    const [orderDetails, setOrderDetails] = useState<salesOrderOverview>()

    const [loading, set_loading] = useState(false)
    const [trackingDetails, setTrackingDetails] = useState<TrackDetailsType>();



    const loadOrderDetails = async () => {

        const res = await api.get<{ success: boolean, data: SalesOrderDetailsResponse }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/sales_order/${id}`,
            mode: 'admin',

        })

        if (res.data.success === true) {
            // setTrackingDetails(res.data.data);
            // setOrderDetails(res.data.data);
            const order = res.data.data
            const eachData: salesOrderOverview = {
                date: order.I19_sale_date,
                orderId: String(order.I19_order_id),
                orderSource: order.I19_order_source,
                deliveryCharge:order.I19_Delivery_charge,
                Price:order.I19_total_price,
                taxPrice:order.I19_tax_price,
                orderStatus: order.orderStatus.I030_order_name,
                address: order.I19_customer_address,
                addressName: order.I19_customer_name,
                customerEmail: order.I19_email!,
                customerName: order.customer.M01_CustomerUsername!,
                orderLines: order.orderLines.map(
                    order => ({
                        // image:order.product.M08_ProductImages as string,
                        price: order.I21_Price,
                        productImage: order.product.M08_ProductImage! as string,
                        productName: order.product.M08_ProductItemName!,
                        quantity: order.I21_Quantity,
                        customization: order.customizations

                    })
                ),
                expectedDeliveryDate: order.expectedDeliveryDate,
                full_order_statuses: order.full_order_statuses,
                order_status: order.orderStatus,
                order_status_dates: order.order_status_dates,
                mobileNo: order.I19_phone_number,
                orderMethod:{
                    id:String(order.I19_P16_payment_method_id),
                    name:order.I19_P16_payment_method_id==paymentTypeId.CashOnDelivery?'Cash on delvery':'Prepaid'
                },
                delivery:order.delivery

            }
            setOrderDetails(eachData)
        }

        

    }



    useEffect(
        () => {

            const initListSetup = () => {
                set_loading(true)
                setOrderDetails(undefined)

                Promise.all(
                    []
                ).then(
                    () => {
                        set_loading(false)
                    }
                ).catch(
                    (err) => {
                        set_loading(false)
                    }
                )

            }
            const initEditSetup = () => {
                set_loading(true)
                loadOrderDetails()
                Promise.all(
                    []
                ).then(
                    () => {
                        set_loading(false)

                    }
                ).catch(
                    (err) => {
                        set_loading(false)

                    }
                )

            }

            if (mode === 'add' && show === true) {
                initListSetup()
            } else if (mode === 'edit' && show === true) {
                initEditSetup()
            } else {
                set_loading(true)

            }


        }, [show]
    )



    return (
        <AdminModal show={show} setShow={setShow} >
            {

                <AdminModalContent className=''>
                    {loading === true
                        ?
                        <div className=' bg-white p-3'>Loading ...</div>
                        :
                        <>

                            <AdminModalHeader>
                                <AdminModalTitle>OrderId: {orderDetails?.orderId}</AdminModalTitle>
                            </AdminModalHeader>
                            <AdminModalMiddle>
                                <div className=' flex flex-col'>
                                    {/* customer and address */}
                                    <div className=' font-bold text-lg'>Customer Details</div>
                                    <div className='p-1 sm:p-4'>
                                        <Label className='font-bold text-gray-600'>Customer Name</Label>
                                        <div>{orderDetails?.customerName}</div>
                                        <Label className='font-bold text-gray-600'>Mobile No</Label>
                                        <div>{orderDetails?.mobileNo}</div>
                                        <div className='h-2'></div>
                                        <Label className='font-bold text-gray-600'>Email</Label>
                                        <div>{orderDetails?.customerEmail}</div>
                                        <div className='h-2'></div>
                                        <Label className='font-bold text-gray-600'>Address</Label>
                                        <div className=' font-bold'>{orderDetails?.addressName}</div>
                                        <div>{orderDetails?.address}</div>
                                    </div>
                                    <div className=' font-bold text-lg'>Order Details</div>
                                    <div className='p-1 sm:p-4'>
                                        <Label className='font-bold text-gray-600'>Order Source</Label>
                                        <div>{orderDetails?.orderSource}</div>
                                        <div className='h-2'></div>
                                        <Label className='font-bold text-gray-600'>Order Method</Label>
                                        <div>{orderDetails?.orderMethod.name}</div>
                                        <div className='h-2'></div>
                                        <Label className='font-bold text-gray-600'>Order Amount</Label>
                                        <div>{orderDetails?.Price}</div>
                                        <div className='h-2'></div>
                                        <Label className='font-bold text-gray-600'>Delivery Charge</Label>
                                        <div>{orderDetails?.deliveryCharge}</div>
                                        <div className='h-2'></div>
                                        <Label className='font-bold text-gray-600'>Tax</Label>
                                        <div>{orderDetails?.taxPrice}</div>
                                        <div className='h-2'></div>
                                        <Label className='font-bold text-gray-600'>Products</Label>
                                        <div>
                                            {
                                                orderDetails?.orderLines.map(
                                                    lines => (
                                                        <div className=' flex flex-col border shadow-sm p-3 rounded-md gap-2'>
                                                            <div className='w-[100px] h-[100px]'>
                                                                <img className='h-full w-full' src={`${process.env.REACT_APP_BACKEND_URL}/${lines.productImage}`} alt="" />
                                                            </div>
                                                            <div className=''>
                                                                <div className='font-semibold text-gray-700'>Product name</div>
                                                                <div>{lines.productName}</div>
                                                            </div>
                                                            <div className=''>
                                                                <div className='font-semibold text-gray-700'>Quantity</div>
                                                                <div>{lines.quantity}</div>
                                                            </div>
                                                            {
                                                                lines.customization ?
                                                                    <div className=''>
                                                                        <div className='font-semibold text-gray-700'>Customization</div>
                                                                        <table>
                                                                            {
                                                                                lines.customization.map(
                                                                                    obj => {
                                                                                        if(obj.M25_type=='dropDown'){
                                                                                            return (
                                                                                                <tr>
                                                                                                    <td className='px-2'>{obj.M25_customization_name}</td>
                                                                                                    <td className='px-2'>{obj.M25_value}</td>
                                                                                                </tr>
                                                                                            )
                                                                                        }else{
                                                                                            return (
                                                                                                <tr>
                                                                                                    <td className='px-2'>{obj.M25_customization_name}</td>
                                                                                                    <td className='px-2'>{obj.M25_inputValue}</td>
                                                                                                </tr>
                                                                                            )
                                                                                            
                                                                                        }
                                                                                    }
                                                                                )
                                                                            }
                                                                        </table>
                                                                    </div>
                                                                    : null
                                                            }
                                                            <div className=''>
                                                                <div className='font-semibold text-gray-700'>Price</div>
                                                                <div>AED <span className=' text-lg font-bold'>{lines.price}</span></div>
                                                            </div>

                                                        </div>
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                    {
                                        orderDetails?.delivery?
                                        <>
                                            <div className=' font-bold text-lg'>Shipping Details</div>
                                            <div className='p-1 sm:p-4'>
                                                <Label className='font-bold text-gray-600'>Tracking number</Label>
                                                <div>{orderDetails?.delivery.I05_Tracking_number}</div>
                                                
                                                <Label className='font-bold text-gray-600'>Expected Delivery</Label>
                                                <div>{orderDetails?.delivery.I05_Estimated_arrival_date}</div>
                                                
                                                <Label className='font-bold text-gray-600'>Delivered on</Label>
                                                <div>{orderDetails?.delivery.I05_Actual_arrival_date}</div>

                                                
                                            </div>
                                        </>
                                        :null
                                    }
                                    <div className=' font-bold text-lg'>Order History</div>
                                    {
                                        orderDetails ?
                                            <div className='pl-6 pt-8'>
                                                <OrderBus
                                                    fullStatuses={orderDetails.full_order_statuses}
                                                    currentStatuses={orderDetails.order_status_dates}
                                                    currentStop={orderDetails.order_status.I030_order_name}
                                                    expectedDeliveryDate={orderDetails.expectedDeliveryDate}
                                                />
                                                {/* <Tracking/> */}
                                            </div>
                                            : <div>
                                                nothing
                                            </div>
                                    }

                                </div>
                            </AdminModalMiddle>
                            <AdminModalFooter>
                                <div className=' flex justify-end'>
                                    <Button variant={'destructive'} onClick={closeModal} >close</Button>

                                </div>
                            </AdminModalFooter>

                        </>}
                </AdminModalContent>
            }
        </AdminModal>
    )
}
